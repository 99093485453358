<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/' }">Home Page</el-breadcrumb-item>
  </el-breadcrumb>
  <el-card>
    <h2>Welcome to Machine Learning Model Market!</h2>
    <search-box :params="searchParams" @get-data="(data) => searchData = data" ref="searchBox" classes="center_layout">
      <el-table ref="tableRef" :data="searchData" @sort-change="(column) => $refs.searchBox.sortData(column)" border stripe class="center_layout">
        <el-table-column :sortable="'custom'" header-align="center" align="center" label="ID" type="index"></el-table-column>

        <el-table-column header-align="center" align="center" label="Logo"
                         prop="img">
          <template v-slot="scope">
            <img :src="scope.row.img" alt="avatar" style="width: 60px; display: inline-block"/>
          </template>
        </el-table-column>
        <el-table-column :sortable="'custom'" header-align="center" align="center" label="Name"
                         prop="modelName" width="500px">
          <template v-slot="scope">
            <a target="_blank" :href="'/viewModel/'+scope.row.id" style="text-decoration: none;font-size: 15px">{{scope.row.modelName}}</a>
          </template>

        </el-table-column>
        <el-table-column :sortable="'custom'" header-align="center" align="center" label="Author"
                         prop="nickname"></el-table-column>
        <el-table-column :sortable="'custom'" header-align="center" align="center" label="FrameWork"
                         prop="modelFramework"
                         width="130px"></el-table-column>
<!--        <el-table-column :sortable="'custom'" header-align="center" align="center" label="Likes" prop="likes"-->
<!--                         width="130px"></el-table-column>-->
        <el-table-column :sortable="'custom'" header-align="center" align="center" label="Price" prop="price"
                         width="130px"></el-table-column>
        <el-table-column :sortable="'custom'" header-align="center" align="center" label="Update Time"
                         prop="updated_time"
                         width="220px"></el-table-column>
        <el-table-column header-align="center" align="center" label="Tags">
          <template v-slot="scope">
            <el-tag
                v-for="(item, i) in scope.row.tags"
                :key="i"
            >{{ item }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="Operations" width="280px">
          <template v-slot="scope">
            <el-button type="primary" :icon="View" size="medium"
                       @click="viewModel(`${scope.row.id}`)">View
            </el-button>
            <el-button
                type="warning"
                :icon="ShoppingCart"
                size="medium"
                @click="viewModel(`${scope.row.id}`,true)"
            >Buy
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </search-box>
  </el-card>
</template>

<script>
import {ShoppingCart, View} from '@element-plus/icons-vue';
export default {
  name: "modelList",
  computed: {
    ShoppingCart() {
      return ShoppingCart
    },
    View() {
      return View
    },
  },
  data() {
    return {
      searchData: [],
      searchParams: {
        queryFields: [
          {
            label: 'Model Name',
            value: 'modelName',
            type: 'text',
            comment: '',
          },
          {
            label: 'Author',
            value: 'nickname',
            type: 'text',
            comment: '',
          },
          {
            label: 'Framework',
            value: 'modelFramework',
            type: 'text',
            comment: '',
          },
          {
            label: 'Price',
            value: 'price',
            type: 'number',
            comment: '',
          },
          {
            label: 'Tag',
            value: 'tags',
            type: 'text',
            comment: '',
          },
          {
            label: 'Update Time',
            comment: ' (Must specify time, not only date)',
            value: 'updated_time',
            type: 'datetime',
          },
        ],
        apiAddress: 'queryModels',
        sortProp: "nickname",
        order:-1,
        defaultSearchProp: "modelName",
      },
    };
  },
  methods: {
    viewModel(id, buy = false) {
      let newPage = this.$router.resolve({
        path: '/viewModel/' + id,
        query: {
          buy: buy,
        },
      })
      window.open(newPage.href, '_blank');
    },
  },
}
</script>

<style scoped>
.el-table th.gutter {
  display: table-cell !important;
}

.el-form {
  text-align: left;
}

:deep(.el-form-item__label) {
  text-align: left !important;
  float: initial !important;
}
</style>
